


























import Vue from 'vue';
import { mapState } from 'vuex';
import Carousel from '@/components/ui/Carousel.vue';


import HeroSection from '@/components/ui/HeroSection.vue';


const SECTION_TYPES = {
	pack: 'pack',
	video: 'video'
}

export default Vue.extend({
	components: {
		HeroSection,
		Carousel
	},
	computed: {
		...mapState({
			collections: (state: any) => state.home.collections,
			banners: (state: any) => state.home.banners
		})
	},
	data: () => ({
		SECTION_TYPES: Object.values(SECTION_TYPES)
	})
});
